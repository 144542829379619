var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"outBox",style:({ left: _vm.left + 'px' })},[_c('div',{staticClass:"content flex"},[_c('img',{attrs:{"src":require("./img/nav_logo@2x.png"),"width":"118"}}),_c('ul',{staticClass:"con_ul flex"},[_c('router-link',{attrs:{"to":"/"}},[_c('li',{class:_vm.selIndex == 1 ? 'selLi' : ''},[_vm._v(" 首页 "),_c('span')])]),_c('li',{staticClass:"product_li",class:_vm.selIndex == 2 ? 'selLi' : '',on:{"mouseover":function($event){(_vm.show = 'block'), (_vm.proHover = 0)},"mouseleave":function($event){(_vm.show = 'none'), (_vm.proHover = null)}}},[_vm._v(" 产品 "),_c('img',{staticClass:"product_img",attrs:{"src":_vm.selIndex == 2
                ? _vm.proHover == 0
                  ? _vm.srcHover
                  : _vm.srcSel
                : _vm.proHover == 0
                ? _vm.srcHover
                : _vm.srcDef}}),_c('span')]),_c('li',{staticClass:"scene_li",class:_vm.selIndex == 3 ? 'selLi' : '',on:{"mouseover":function($event){(_vm.r_show = 'block'), (_vm.sceHover = 0)},"mouseleave":function($event){(_vm.r_show = 'none'), (_vm.sceHover = null)}}},[_vm._v(" 应用场景 "),_c('img',{staticClass:"scene_img",attrs:{"src":_vm.selIndex == 3
                ? _vm.sceHover == 0
                  ? _vm.srcHover
                  : _vm.srcSel
                : _vm.sceHover == 0
                ? _vm.srcHover
                : _vm.srcDef,"alt":""}}),_c('span')]),_c('router-link',{attrs:{"to":"/developer"}},[_c('li',[_vm._v(" 开发者中心 "),_c('span')])]),_c('router-link',{attrs:{"to":"/call"}},[_c('li',[_vm._v(" 联系我们 "),_c('span')])]),_c('router-link',{attrs:{"to":"/demo"}},[_c('li',[_vm._v(" DEMO预览 "),_c('span')])])],1)]),_c('div',{staticClass:"hover_div",style:({ display: _vm.show }),on:{"mouseover":function($event){_vm.show = 'block'},"mouseleave":function($event){_vm.show = 'none'}}},[_c('div',{staticClass:"right"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,staticClass:"hoverItem"},[_c('div',[_c('img',{attrs:{"src":item.icon}}),_c('h3',[_vm._v(_vm._s(item.title))])]),_vm._l((item.list),function(text,i){return _c('span',{key:i,class:index == _vm.itemSel && i == _vm.spanSel ? 'spanSel' : '',on:{"click":function($event){return _vm.toProduct(index, i)}}},[_vm._v(_vm._s(text))])})],2)}),0)]),_c('div',{staticClass:"hover_div",style:({ display: _vm.r_show }),on:{"mouseover":function($event){_vm.r_show = 'block'},"mouseleave":function($event){_vm.r_show = 'none'}}},[_c('div',{staticClass:"right"},_vm._l((_vm.sceneNav),function(item,index){return _c('div',{key:index,staticClass:"hoverItem"},[_c('div',[_c('img',{attrs:{"src":item.img}}),_c('h3',[_vm._v(_vm._s(item.title))])]),_c('span',{staticClass:"scene_span",class:index == _vm.scene ? 'spanSel' : '',on:{"click":function($event){return _vm.goScene(index)}}},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }