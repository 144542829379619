<template>
  <div class="box">
    <div class="outBox" :style="{ left: left + 'px' }">
      <div class="content flex">
        <img src="./img/nav_logo@2x.png" width="118" />
        <ul class="con_ul flex">
          <router-link to="/"
            ><li :class="selIndex == 1 ? 'selLi' : ''">
              首页
              <span></span></li
          ></router-link>
          <li
            class="product_li"
            :class="selIndex == 2 ? 'selLi' : ''"
            @mouseover="(show = 'block'), (proHover = 0)"
            @mouseleave="(show = 'none'), (proHover = null)"
          >
            产品
            <img
              class="product_img"
              :src="
                selIndex == 2
                  ? proHover == 0
                    ? srcHover
                    : srcSel
                  : proHover == 0
                  ? srcHover
                  : srcDef
              "
            />
            <span></span>
          </li>
          <li
            class="scene_li"
            :class="selIndex == 3 ? 'selLi' : ''"
            @mouseover="(r_show = 'block'), (sceHover = 0)"
            @mouseleave="(r_show = 'none'), (sceHover = null)"
          >
            应用场景
            <img
              class="scene_img"
              :src="
                selIndex == 3
                  ? sceHover == 0
                    ? srcHover
                    : srcSel
                  : sceHover == 0
                  ? srcHover
                  : srcDef
              "
              alt=""
            />
            <span></span>
          </li>
          <router-link to="/developer"
            ><li>
              开发者中心
              <span></span></li
          ></router-link>
          <router-link to="/call"
            ><li>
              联系我们
              <span></span></li
          ></router-link>
          <router-link to="/demo">
            <li>
              DEMO预览
              <span></span></li
          ></router-link>
        </ul>
      </div>
      <!-- 产品/应用悬停显示 -->
      <div
        class="hover_div"
        :style="{ display: show }"
        @mouseover="show = 'block'"
        @mouseleave="show = 'none'"
      >
        <div class="right">
          <div class="hoverItem" v-for="(item, index) in navList" :key="index">
            <div>
              <img :src="item.icon" />
              <h3>{{ item.title }}</h3>
            </div>
            <span
              v-for="(text, i) in item.list"
              :class="index == itemSel && i == spanSel ? 'spanSel' : ''"
              @click="toProduct(index, i)"
              :key="i"
              >{{ text }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="hover_div"
        :style="{ display: r_show }"
        @mouseover="r_show = 'block'"
        @mouseleave="r_show = 'none'"
      >
        <div class="right">
          <div class="hoverItem" v-for="(item, index) in sceneNav" :key="index">
            <div>
              <img :src="item.img" />
              <h3>{{ item.title }}</h3>
            </div>
            <span
              :class="index == scene ? 'spanSel' : ''"
              class="scene_span"
              @click="goScene(index)"
            >
              {{ item.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* import Bus from "@/util/bus"; //传参 */
export default {
  data() {
    return {
      navList: [
        {
          title: "美颜SDK/萌颜SDK",
          icon: require("./img/icon_nav_product_meiyan@2x.png"),
          list: [
            "全局美颜",
            "精准美肤",
            "精致美型",
            "动态贴纸",
            "滤镜特效",
            "美妆特效",
            "抖动特效",
            "互动贴纸",
            "水印LOGO",
            "精致面具",
            "哈哈镜",
            "一键美颜",
            "脸型精修",
            "绿幕抠图",
            "长腿瘦身",
            "手势识别",
            "发色变换",
            "动态礼物",
            "人像抠图",
          ],
        },
        {
          title: "智能识别",
          icon: require("./img/icon_nav_product_shibie@2x.png"),
          list: ["人脸追踪", "人脸对比", "人像识别", "毛发识别", "手势识别"],
        },
        {
          title: "核心技术",
          icon: require("./img/icon_nav_product_jishu@2x.png"),
          list: [
            "人脸检测",
            "人脸关键点",
            "姿势估计",
            "表情识别",
            "图像处理",
            "身份证识别",
            "人脸比对",
            "2D动态贴纸",
            "3D动态贴纸",
          ],
        },
      ],
      // 应用场景数据
      sceneNav: [
        {
          title: "直播场景",
          img: require("./img/icon_nav_changjing_zhibo@2x.png"),
          text: "应用于娱乐直播、电商直播、教育直播、传媒直播等直播场景的互动玩法",
        },
        {
          title: "视频场景",
          img: require("./img/icon_nav_changjing_video@2x.png"),
          text: "应用于短视频、视频通话、视频编辑等视频场景的互动玩法",
        },
        {
          title: "智慧屏场景",
          img: require("./img/icon_nav_changjing_zhihuiping@2x.png"),
          text: "应用于线下大屏、广告屏、美妆镜等不同屏幕场景的互动玩法",
        },
      ],
      srcDef: require("./img/icon_nav_xiangxia_def@2x.png"), //默认图标
      srcSel: require("./img/icon_nav_xiangxia_sel@2x.png"), //选中图标
      srcHover: require("./img/icon_nav_xiala_hover@2x.png"), //悬停图标
      show: "none", //产品悬停
      r_show: "none", //应用悬停
      selIndex: 1, //产品、应用选中样式
      proHover: null, //产品悬停图标
      sceHover: null, //应用悬停图标
      spanSel: null, //列表显示
      itemSel: 0,
      scene: null,
      left: 0, //导航左右滑动距离
    };
  },
  watch: {
    $route(to, from) {
      let name = this.$route.matched[0].meta.name;
      if (name == "Product") {
        this.selIndex = 2;
        this.scene = null;
      } else if (name == "Scene") {
        this.selIndex = 3;
        this.spanSel = null;
      } else if (name == "Home") {
        this.selIndex = 1;
        this.spanSel = null;
        this.scene = null;
      } else {
        this.selIndex = 0;
        this.spanSel = null;
        this.scene = null;
      }
    },
  },
  created() {
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll);
  },

  methods: {
    // 导航栏滑动
    scroll() {
      let x = window.pageXOffset;
      this.left = -x;
    },
    // 产品点击跳转
    toProduct(index, id) {
      this.spanSel = id;
      this.itemSel = index;
      if (index == 0) {
        //点击跳转美颜sdk
        this.$router.push({ path: "/product", query: { id: id } });
      } else if (index == 1) {
        //点击跳转智能识别
        this.$router.replace({
          path: "/product/intelligent",
          query: { id: id },
        });
      } else {
        // 点击跳转核心技术
        this.$router.replace({ path: "/product/core", query: { id: id } });
      }
      this.show = "none";
    },
    // 跳转应用场景
    goScene(index) {
      this.scene = index;
      this.$router.push({ path: "/scene", query: { id: index } });
      this.r_show = "none";
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// 定位高度塌陷
.box {
  width: 100%;
  height: 68px;
}
.outBox {
  width: 100%;
  background: #1d1d1d;
  position: fixed;
  top: 0;
  z-index: 99;
  .content {
    width: 79.1%;
    height: 68px;
    margin: auto;
    .con_ul {
      width: 50%;
      font-size: 14px;
      font-weight: 400;
      li {
        width: 100px;
        height: 63px;
        line-height: 63px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        span {
          display: block;
          width: 20px;
          height: 3px;
          background: #4c8cf5;
          margin: auto;
          display: none;
        }
        .product_img,
        .scene_img {
          vertical-align: middle;
          width: 8px;
          margin-left: 5px;
        }
      }
      li:hover,
      .selLi,
      .router-link-exact-active li {
        background: #101010;
        color: #4c8cf5;
        span {
          display: block;
        }
      }
    }
  }
  // 鼠标悬停显示
  .hover_div {
    display: none;
    width: 100%;
    height: 432px;
    background: rgba($color: #1d1d1d, $alpha: 0.9);
    position: absolute;
    top: 68px;
    .right {
      position: absolute;
      right: 13%;
      .hoverItem {
        width: 574px;
        font-weight: 400;
        font-size: 14px;
        color: #999;
        img {
          width: 16px;
          margin-right: 10px;
        }
        h3 {
          display: inline-block;
          height: 60px;
          font-size: 16px;
          color: #4c8cf5;
          line-height: 60px;
        }
        span {
          display: inline-block;
          width: 91px;
          height: 33px;
          cursor: pointer;
        }
        span:hover,
        .spanSel {
          color: #fff;
        }
        .scene_span {
          width: 100%;
        }
      }
    }
  }
}

/* 屏幕小于 */
@media screen and (max-width: 1440px) {
  .outBox {
    width: 1440px;
  }
}
</style>