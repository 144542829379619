import Vue from 'vue';
import VueRouter from 'vue-router';

// 路由重复点击报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const Home = () => import('@/views/home/index');
const Product = () => import('@/views/product/index');
const Scene = () => import('@/views/scene/index');
const Developer = () => import('@/views/developer/index');
const Call = () => import('@/views/call/index');
const Demo = () => import('@/views/demo/index');

// 首页子路由
const Index = () => import('@/views/home/child/index');
const Serve = () => import('@/views/home/child/serve');
const Cooperation = () => import('@/views/home/child/cooperation');
const Join = () => import('@/views/home/child/join');
const Company = () => import('@/views/home/child/company');
const ResourcePool = () => import('@/views/home/child/resource-pool.vue');
const Privacy = () => import('@/views/home/child/privacy.vue');
// 产品子路由
const BeautySDK = () => import('@/views/product/beautySDK/index');
const Intelligent = () => import('@/views/product/intelligent/index');
const Core = () => import('@/views/product/core/index');
// 开发者中心子路由
const FristPage = () => import('@/views/developer/child/fristPage');
const Platform = () => import('@/views/developer/child/platform');
const NavPage = () => import('@/views/developer/child/navPage/index');
// 新手指南
const New = () => import('@/views/developer/child/navPage/new/index');
const SDK = () => import('@/views/developer/child/navPage/new/sdk');
// 集成开发
const Integrated = () => import('@/views/developer/child/navPage/integrated/index');
const Key = () => import('@/views/developer/child/navPage/integrated/key');
const IOS = () => import('@/views/developer/child/navPage/integrated/ios');
const Android = () => import('@/views/developer/child/navPage/integrated/android');
const Third = () => import('@/views/developer/child/navPage/integrated/third');
// API中心
const APICenter = () => import('@/views/developer/child/navPage/apiAccess/index');
const IOSAccess = () => import('@/views/developer/child/navPage/apiAccess/iosAccess');
const AndroidAccess = () => import('@/views/developer/child/navPage/apiAccess/androidAccess');
// 贴纸使用文档
const Sticker = () => import('@/views/developer/child/navPage/sticker/index');
const Tools = () => import('@/views/developer/child/navPage/sticker/tools');
// 常见问题
const Common = () => import('@/views/developer/child/navPage/common/index');
const UpGrade = () => import('@/views/developer/child/navPage/common/upGrade');
const IOSRelated = () => import('@/views/developer/child/navPage/common/iosRelated');
const AndRelated = () => import('@/views/developer/child/navPage/common/andRelated');
const ThirdParty = () => import('@/views/developer/child/navPage/common/thirdParty');
const StickerTools = () => import('@/views/developer/child/navPage/common/stickerTools');
const Other = () => import('@/views/developer/child/navPage/common/other');
//合规
const Guide = () => import('@/views/developer/child/navPage/compliance/guide');



// 更新说明
const Update = () => import('@/views/developer/child/navPage/update/index');
const AndUpdate = () => import('@/views/developer/child/navPage/update/andUpdate');
const IosUpdate = () => import('@/views/developer/child/navPage/update/iosUpdate');


const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home,
    meta: {
      title: '首页',
      name: 'Home',
    },
    children: [
      {
        path: '',
        component: Index
      },
      {
        path: 'serve',
        name: 'Serve',
        component: Serve
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: Privacy
      },
      {
        path: 'cooperation',
        name: 'Cooperation',
        component: Cooperation
      },
      {
        path: 'join',
        name: 'Join',
        component: Join
      },
      {
        path: 'company',
        name: 'Company',
        component: Company
      },
      {
        path: 'resourcePool',
        name: 'ResourcePool',
        component: ResourcePool
      }
    ]
  },
  {
    path: '/product',
    component: Product,
    meta: {
      title: '产品',
      name: 'Product',
    },
    children: [
      {
        path: '',
        component: BeautySDK,
        meta: {
          title: '美颜sdk',
          name: 'BeautySDK',
        }
      },
      {
        path: 'intelligent',
        component: Intelligent,
        meta: {
          name: 'Intelligent',
          title: '智能识别'
        }
      },
      {
        path: 'core',
        component: Core,
        meta: {
          name: 'Core',
          title: '核心技术'
        }
      }
    ]
  },
  {
    path: '/scene',
    component: Scene,
    meta: {
      name: 'Scene',
    }
  },
  {
    path: '/developer',
    component: Developer,
    meta: {
      title: '开发者中心',
      name: 'Developer',//面包屑导航以name做判断
    },
    children: [
      {
        path: '',
        name: 'FristPage',
        component: FristPage,
      },
      {
        path: 'navPage',
        component: NavPage,
        children: [
          {
            path: '/',
            redirect: 'sdk',
            component: New,
            meta: {
              title: '新手指南',
              name: 'New',
            },
            children: [
              {
                path: 'sdk',
                component: SDK,
                meta: {
                  title: 'SDK与Tools',
                  name: 'SDK',
                },
              }
            ]
          },
          {
            path: 'integrated',
            component: Integrated,
            meta: {
              title: '集成开发',
              name: 'Integrated',
            },
            children: [
              {
                path: '',
                component: Key,
                meta: {
                  title: '秘钥Key',
                  name: 'Key',
                },
              },
              {
                path: 'ios',
                component: IOS,
                meta: {
                  title: 'IOS平台',
                  name: 'IOS',
                },
              },
              {
                path: 'android',
                component: Android,
                meta: {
                  title: 'Android平台',
                  name: 'Android',
                },
              },
              {
                path: 'third',
                component: Third,
                meta: {
                  title: '第三方集成方案',
                  name: 'Third',
                },
              },
            ]
          },
          {
            path: 'apiCenter',
            component: APICenter,
            meta: {
              title: 'API中心',
              name: 'APICenter',
            },
            children: [
              {
                path: '',
                component: IOSAccess,
                meta: {
                  title: 'IOS接入',
                  name: 'IOSAccess',
                },
              },
              {
                path: 'androidAccess',
                component: AndroidAccess,
                meta: {
                  title: 'Android接入',
                  name: 'AndroidAccess',
                },
              },
            ]
          },
          {
            path: 'sticker',
            component: Sticker,
            meta: {
              title: '贴纸Tools',
              name: 'Sticker',
            },
            children: [
              {
                path: 'tools',
                component: Tools,
                meta: {
                  title: 'Tools使用文档',
                  name: 'Tools',
                },
              },
            ]
          },
          {
            path: 'common',
            component: Common,
            meta: {
              title: '常见问题',
              name: 'Common',
            },
            children: [
              {
                path: '',
                component: UpGrade,
                meta: {
                  title: '升级新ui',
                  name: 'UpGrade',
                },
              },
              {
                path: 'iosRelated',
                component: IOSRelated,
                meta: {
                  title: 'IOS相关',
                  name: 'IOSRelated',
                },
              },
              {
                path: 'andRelated',
                component: AndRelated,
                meta: {
                  title: 'Android相关',
                  name: 'AndRelated',
                },
              },
              {
                path: 'thirdParty',
                component: ThirdParty,
                meta: {
                  title: '第三方集成相关',
                  name: 'ThirdParty',
                },
              },
              {
                path: 'stickerTools',
                component: StickerTools,
                meta: {
                  title: '贴纸Tools相关',
                  name: 'StickerTools',
                },
              },
              {
                path: 'other',
                component: Other,
                meta: {
                  title: '其他问题',
                  name: 'Other',
                },
              },
            ]
          },
          {
            path: 'update',
            component: Update,
            meta: {
              title: '更新说明',
              name: 'Update',
            },
            children: [
              {
                path: '',
                component: AndUpdate,
                meta: {
                  title: 'Android端',
                  name: 'AndUpdate',
                },
              },
              {
                path: 'iosUpdate',
                component: IosUpdate,
                meta: {
                  title: 'IOS端',
                  name: 'IosUpdate',
                },
              },

            ]
          },

          {
            path: 'compliance',
            component: Guide,
            meta: {
              title: '合规使用说明',
              name: 'Compliance',
            },
            children: [
              {
                path: 'guide',
                component: Tools,
                meta: {
                  title: '合规使用说明',
                  name: 'guide',
                },
              },
            ]
          },

        ]
      },
      {
        path: 'platform',
        component: Platform,
        meta: {
          title: '拓幻TiSDK管理平台',
          name: 'Platform',
        },
      }
    ]
  },
  {
    path: '/call',
    name: Call,
    component: Call
  },
  {
    path: '/demo',
    name: Demo,
    component: Demo
  }
];

const router = new VueRouter({
  /* mode: 'history', */
  routes
});
// 页面定位顶部
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});


export default router;
