<template>
  <div>
    <TopNav></TopNav>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<script>
import TopNav from "@/components/TopNav/index"; //头部导航
import Footer from "@/components/Footer/index";
export default {
  components: {
    TopNav,
    Footer,
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: Source Han Sans CN;
}
a {
  text-decoration: none;
  color: black;
}
button,
input {
  border: 0;
  outline: none;
}
button:focus {
  border: 0;
  outline: none;
}
</style>
